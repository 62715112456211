import React from "react"
import Layout from "../layouts"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Hero from "../components/Hero"
import LetterAnimation from "../animations/LetterAnimation"
import { PageContainer } from "../styles/global"
import { remCalc, pageMargins } from "../styles/utils"
import { typography, color } from "../styles/variables"

import NextPage from "../components/NextPage"
import CircleAndText from "../components/CircleAndText"
import StyledStats from "../styles/StyledStats"
import Line from "../components/Line"
import animationData from "../animations/DESKTOP_MOBILE/05-Covid19.json"
import mobileAnimationData from "../animations/DESKTOP_MOBILE/05-Covid19_MOBILE.json"
import useIsTablet from "../hooks/useIsTablet"
import List from "../components/List"
import Item from "../components/List/Item"
import Arrow from "../images/svgs/Explore"

const StyledCovid = styled.div`
  position: relative;
  width: 100%;

  & .covid_circle-text {
    & .circle {
      width: ${remCalc(300)};
      margin-left: 3rem;
      position: relative;
      grid-column: 1/6;
      @media only screen and (max-width: 1024px) and (min-width: 640px) {
        margin-left: 0rem;
        width: 281px;
      }

      @media only screen and (max-width: 640px) {
        grid-row: 1/2;
        width: 281px;
        justify-self: center;
        margin-left: 0;
        grid-column: 1/13;
      }
    }
    & .text {
      width: 100%;
      min-width: 25rem;
      position: relative;
      grid-column: 6/13;
      align-self: center;

      p {
        margin-bottom: 1rem;
        line-height: 1.5em;

        a {
          color: black;
        }
      }

      &-large {
        p {
          font-size: 2rem;
          line-height: 2.5rem;
          margin-bottom: 0;
        }
      }
      @media only screen and (max-width: 1024px) and (min-width: 640px) {
        min-width: unset;
        grid-column: 7/13;
        p {
          font-size: 24px;
        }
      }
      @media only screen and (max-width: 640px) {
        min-width: unset;
        grid-row: 2/3;
        grid-column: 1/13;
        &.red {
          margin-top: 1rem;
        }
        p {
          font-size: 20px;
          line-height: 30px;
          text-align: center;
        }
      }
    }
    &.reverse {
      padding-top: 0;
      margin-top: 112px;
      & .circle {
        margin-left: 0rem;

        grid-column: 8/13;
        @media only screen and (max-width: 640px) {
          grid-column: 1/13;
        }
      }
      & .text {
        grid-column: 1/7;
        @media only screen and (max-width: 640px) {
          grid-column: 1/13;
        }
      }
      @media only screen and (max-width: 1024px) and (min-width: 640px) {
        margin-top: 64px;
      }
      @media only screen and (max-width: 640px) {
        margin-top: 0;
      }
    }
  }
  .covid_square-text {
    position: relative;

    & .square {
      grid-column: 1/6;
      @media only screen and (max-width: 640px) {
        grid-column: 1/13;
        grid-row: 1/2;
      }
    }
    & .text {
      grid-column: 7/13;
      font-size: 1.25rem;
      line-height: ${remCalc(30)};
      align-self: center;
      @media only screen and (max-width: 640px) {
        grid-column: 1/13;
        grid-row: 2/3;
      }
    }
    & .address {
      margin-top: 1rem;
    }
  }

  & .image_red-banner {
    width: 25rem;
    position: absolute;
    right: 2rem;
    bottom: 2rem;

    & p {
      width: 100%;
      position: relative;
      background: var(--covid-header-color);
      padding: 1rem;
      color: white;
      border-radius: 6px;
    }
    @media only screen and (max-width: 1024px) {
      position: relative;
      width: 100%;
      margin: 2rem auto;
      right: unset;
      bottom: unset;
      ${pageMargins};
      & p {
        font-size: 1.5rem;
        line-height: 33px;
      }
    }
    @media only screen and (max-width: 640px) {
      & p {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
`

const Covid19 = ({ location }) => {
  const isTablet = useIsTablet()
  const data = useStaticQuery(graphql`
    query {
      nurse: file(relativePath: { eq: "covid-nurse.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nhs: file(relativePath: { eq: "covid-nhs-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1700) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      love: file(relativePath: { eq: "covid-love.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blue: file(relativePath: { eq: "covid-blue.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ambulance: file(relativePath: { eq: "covid-ambulance-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bgImage: file(relativePath: { eq: "covid-header-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout location={location}>
      <Hero
        bg="black"
        animationData={animationData}
        mobileAnimationData={mobileAnimationData}
        bgImage={data.bgImage}
        mobileColors={color.header.covid_19}
        location={location.pathname}
        headerColor="#DA3C2A"
      >
        {isTablet ? (
          <h1 className="hero_title">Our response to Covid-19</h1>
        ) : (
          <LetterAnimation duration={0.7} delay={0.017} initDelay={0.2}>
            <h1 className="hero_title text-animated">
              <span className="line-inner">Our response</span>
            </h1>
            <h1 className="hero_title text-animated">
              <span className="line-inner">to Covid-19</span>
            </h1>
          </LetterAnimation>
        )}
      </Hero>
      <StyledCovid>
        <PageContainer>
          <CircleAndText image={data.blue}>
            <div
              data-scroll
              data-scroll-speed="1"
              className="circle-text_container first-section"
            >
              <p>The past year brought unprecedented challenges for everyone. In response, we focused on two clear outcomes. The first was to do everything we could to ensure Landsec emerged from Covid-19 in as strong a position as possible.</p>
              <p>The second was to re-examine the purpose, strategy and culture that will position us to achieve growth and make the most of Landsec’s undoubted potential.</p>
              <p>But while we planned for the future at Landsec, we also focused on the present challenges of Covid-19. Our response for our customers was rapid, flexible and always with safety at its heart. Inevitably, the impact on our customers and our business was still significant.</p>
              <List device="desktop">
                <Item scrollElementClass="overview">
                  <p>Overview</p>
                  <Arrow />
                </Item>
                <Item scrollElementClass="our-customers">
                  <p>Our customers</p>
                  <Arrow />
                </Item>
                <Item scrollElementClass="our-people">
                  <p>Our people</p>
                  <Arrow />
                </Item>
                <Item scrollElementClass="our-communities">
                  <p>Our communities</p>
                  <Arrow />
                </Item>
                <Item scrollElementClass="our-partners">
                  <p>Our partners</p>
                  <Arrow />
                </Item>
              </List>
            </div>
            <List device="tablet">
              <Item scrollElementClass="overview">
                <p>Overview</p>
                <Arrow />
              </Item>
              <Item scrollElementClass="our-customers">
                <p>Our customers</p>
                <Arrow />
              </Item>
              <Item scrollElementClass="our-people">
                <p>Our people</p>
                <Arrow />
              </Item>
              <Item scrollElementClass="our-communities">
                <p>Our communities</p>
                <Arrow />
              </Item>
              <Item scrollElementClass="our-partners">
                <p>Our partners</p>
                <Arrow />
              </Item>
            </List>
            <Line />
          </CircleAndText>
          <StyledStats className="covid-stats overview single" align="flex-start">
            <div className="stat_item flex-tablet">
              <div className="stat_title">
                <h3 className="red">£80m</h3>
              </div>
              <div className="stat_para">
                <p>customer support fund of £80m established for occupiers who most needed our help to survive.</p>
              </div>
            </div>
            <div className="stat_item flex-tablet">
              <div className="stat_title red">
                <h3>£42m</h3>
              </div>
              <div className="stat_para">
                <p>of rent concessions for customers to date.</p>
              </div>
            </div>
            <div className="stat_item flex-tablet">
              <div className="stat_title red">
                <h3>£0.9m</h3>
              </div>
              <div className="stat_para">
                <p>in topped up furlough funds to service partners to allow them to pay 100% of wages.</p>
              </div>
            </div>
            <Line />
          </StyledStats>
          <div className="covid_circle-text section-padding grid-wrapper">
            <div className="circle" data-scroll data-scroll-speed="1">
              <Img fluid={data.nurse.childImageSharp.fluid} />
            </div>
            <div className="text text-large red">
              <p>Board of Directors waived 20% of their base salaries or fees for a period of three months effective 1 May 2020, with the money supplementing our Covid-19 support fund.</p>
            </div>
          </div>
          <div className="page_title-subtitle grid-wrapper section-padding our-customers">
            <div className="title red">
              <h2>Our customers</h2>
            </div>
            <div className="subtitle">At each phase of lockdown and subsequent easing of restrictions, we worked with our customers to help them respond and operate in a safe and effective way. </div>
            <Line />
          </div>
          <div className="page_title-subtitle grid-wrapper section-padding our-people">
            <div className="title red">
              <h2>Our people</h2>
            </div>
            <div className="subtitle">Communication is crucial, so we established a business resilience team to guide our people during the year, with regular updates on how our offices and assets would operate, mental health and wellbeing support, additional advice for line managers to help them support their teams, and resource planning to ensure everyone could take their holidays and not miss out on vital family time.</div>
          </div>
          <div className="image_full-width">
            <Img fluid={data.nhs.childImageSharp.fluid} />
            <div className="image_red-banner desktop">
              <p>
                Piccadilly Lights displayed key public health messaging, free of
                charge.
              </p>
            </div>
          </div>
          <div className="page_title-subtitle grid-wrapper section-padding our-communities">
            <div className="title red">
              <h2>Our communities</h2>
            </div>
            <div className="subtitle">
              <p>The pandemic has disproportionately affected particular groups in UK society, with issues like poverty, unemployment, mental health and social inequality increasing.  Through our social sustainability programmes we’ve therefore increased our focus on supporting those facing barriers including young people, prison leavers, and people experiencing homelessness and long-term unemployment.</p>
            </div>
          </div>
          <div className="covid_circle-text reverse section-padding grid-wrapper">
            <div className="text">
              <p>We also set up our Covid-19 community support fund to provide both immediate and long-term assistance to our charity partners.</p>
              <p><a href="https://landsec.com/sustainability/creating-jobs-and-opportunities" target="_blank" rel="noopener noreferrer nofollow">Read more about our community response</a>.</p>
            </div>
            <div className="circle" data-scroll data-scroll-speed="1">
              <Img fluid={data.love.childImageSharp.fluid} />
            </div>
            <Line />
          </div>
          <div className="page_title-subtitle grid-wrapper section-padding our-partners">
            <div className="title red">
              <h2>Our partners</h2>
            </div>
            <div className="subtitle">
              <p>Our on-site developments are controlled and operated by our contractors. We remained in constant communication with them throughout the year, to ensure our developments could progress while maintaining the safety of their people.</p>
            </div>
          </div>
          <NextPage
            page={{
              title: "Jobs",
              path: "jobs",
            }}
            colorKey="jobs"
          />
        </PageContainer>
      </StyledCovid>
    </Layout>
  )
}

export default Covid19
